import * as React from 'react'
import { useMutation } from '@apollo/react-hooks'
import Link from 'next/link'
import { useFlag, useFlagQueryFn } from 'toggled'

import { addCouponCodeMutation } from '~/gql/mutations'
import { checkoutQuery, orderCouponsQuery, userCartQuery } from '~/gql/queries'
import { useIsMobile, useVendor } from '~/hooks'
import NewButton from '~/truck/new-button/NewButton'
import notifier from '~/truck/notifier'
import { handleSubmissionErrors } from '~/utils'
import { flagQueries, flags } from '~/utils/constants'

import {
  StyledContainer,
  StyledDialogContent,
  StyledDialogFooter,
  StyledDialogHeader,
  StyledField,
  StyledFieldLabel,
} from './CouponModal.style'

interface CouponModal {
  isStandaloneModal: boolean
  onClose: () => void
  orderId?: string
}

export function CouponModal(props: CouponModal) {
  const { onClose, isStandaloneModal = true, orderId } = props

  const vendor = useVendor()

  const isMobile = useIsMobile()

  const [couponCode, setCouponCode] = React.useState('')

  const hasTheBestReward = vendor.settings?.promotions?.applyTheBestReward ?? false

  const [error, setError] = React.useState<string | React.ReactElement>('')

  const [addCouponCode, { loading }] = useMutation(addCouponCodeMutation)

  const showCartInMainLayoutFF = useFlag(flags.SHOW_CART_IN_MAIN_LAYOUT)

  const COUPON_MAX_LENGTH = 24

  const flagQueryFn = useFlagQueryFn()

  const canSelectTimeRange = flagQueryFn(flagQueries.CAN_SELECT_TIME_RANGE)

  const withPurchaseOrderStep = flagQueryFn(flags.UPLOAD_PURCHASE_ORDER)

  async function onApplyClick() {
    setError(null)

    const mutationConfig = {
      variables: { couponCode },
      refetchQueries: [],
    }

    if (isStandaloneModal) {
      mutationConfig.refetchQueries.push({
        query: orderCouponsQuery,
        variables: {
          orderId,
        },
      })

      mutationConfig.refetchQueries.push({
        query: checkoutQuery,
        variables: {
          canSelectTimeRange,
          withPurchaseOrderStep,
        },
      })
    } else {
      mutationConfig.refetchQueries.push({
        query: userCartQuery,
        variables: {
          runPromotions: !showCartInMainLayoutFF,
        },
      })
    }

    try {
      await addCouponCode(mutationConfig)

      notifier.success('El cupón ha sido agregado')

      onClose()
    } catch (error) {
      const messages = handleSubmissionErrors(error)

      if (messages.couponCode) {
        let errorContent: string | React.ReactElement = messages.couponCode as string

        if (/inicio de sesión/.test(errorContent)) {
          errorContent = (
            <span>
              Código de cupón requiere <Link href="/login">inicio de sesión</Link>
            </span>
          )
        }

        setError(errorContent)
      }
    }
  }

  return (
    <StyledContainer $isStandaloneModal={isStandaloneModal}>
      <StyledDialogHeader
        title={isStandaloneModal ? 'Cupón' : null}
        closable
        onClose={onClose}
        closeIconSize="sm"
        closeIconColor="black"
        $isStandaloneModal={isStandaloneModal}
        $isMobile={isMobile}
      ></StyledDialogHeader>
      <StyledDialogContent $isStandaloneModal={isStandaloneModal} $isMobile={isMobile}>
        <StyledField
          value={couponCode}
          error={error}
          standalone
          placeholder="Código de promoción"
          onChange={event => setCouponCode(event.target.value)}
          onKeyUp={event => {
            if (event.key === 'Enter') {
              onApplyClick()
            }
          }}
          maxLength={COUPON_MAX_LENGTH}
        />
        {hasTheBestReward && (
          <StyledFieldLabel as="span" $textStyle="h6Regular" $color="grayscale70">
            Se aplica el cupón o promoción con el mayor descuento.
          </StyledFieldLabel>
        )}
      </StyledDialogContent>
      <StyledDialogFooter $isStandaloneModal={isStandaloneModal} $isMobile={isMobile}>
        <NewButton onClick={onApplyClick} loading={loading}>
          Aplicar
        </NewButton>
      </StyledDialogFooter>
    </StyledContainer>
  )
}
